import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  ServerError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
import { getMainDefinition } from '@apollo/client/utilities';
import { OperationDefinitionNode } from 'graphql';
import omitDeep from '@wertarbyte/omit-deep';
import runtimeConfig from './runtimeConfig';
import JWT from 'jwt-client';

export const serverUrl =
  localStorage.getItem('serverUrl') ||
  runtimeConfig.serverUrl ||
  'http://localhost:4000';

export const graphQLServerURL = `${serverUrl}/graphql`;

const authLink = setContext((_, { headers }) => {
  const token = JWT.get();
  return {
    headers: {
      ...headers,
      Authorization: token ?? '',
    },
  };
});

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const keysToOmit = ['__typename']; // more keys like timestamps could be included here

  const definition = getMainDefinition(operation.query);
  if ((definition as OperationDefinitionNode)?.operation === 'mutation') {
    operation.variables = omitDeep(operation.variables, keysToOmit, false);
  }
  return forward ? forward(operation) : null;
});

const cache = new InMemoryCache();

export default new ApolloClient({
  link: ApolloLink.from([
    cleanTypenameLink,
    onError(({ networkError, graphQLErrors }) => {
      if (
        (networkError as ServerError)?.statusCode === 401 ||
        graphQLErrors?.some(
          (e) =>
            e.extensions?.exception?.status === 401 ||
            e.message === 'Unauthorized',
        )
      ) {
        // not logged in
        JWT.forget();
        window.location.href = '/';
      }
    }),
    authLink,
    new HttpLink({ uri: graphQLServerURL }),
  ]),
  cache,
});
