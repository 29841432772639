import { ApolloClient } from '@apollo/client';
import JWT from 'jwt-client';
import { serverUrl } from '../../util/apolloClient';

export const login = async (
  accountId: string,
  password: string,
): Promise<boolean> => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ accountId, password }),
  };
  const { accessToken } = await fetch(
    `${serverUrl}/authentication`,
    requestOptions,
  ).then((resp) => resp.json());

  if (!accessToken) {
    return false;
  }

  const token = JWT.read(accessToken);
  if (JWT.validate(token)) {
    JWT.keep(token);
    return true;
  } else {
    JWT.forget();
    return false;
  }
};

export function logout(apolloClient: ApolloClient<unknown>): void {
  JWT.forget();
  apolloClient.clearStore();
  // disconnectSubscriptionClient();
}
