import { PaletteType, Theme } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

export const muiTheme = (themeType: PaletteType = 'light'): Theme =>
  createMuiTheme({
    palette: {
      primary: {
        main: '#005091',
      },
      secondary: {
        main: '#38318A',
      },
      error: {
        main: '#f44336',
      },
      type: themeType,
    },
  });
