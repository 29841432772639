import {
  CssBaseline,
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import {
  MuiThemeProvider,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DialogProvider, TooltipIconButton } from '@wa/werkstoff-core';
import checkIE from 'check-ie';
import deLocale from 'date-fns/locale/de';
import { SnackbarProvider } from 'material-ui-snackbar-provider';
import {
  GoogleChrome,
  MicrosoftEdge,
  AppleSafari,
  Firefox,
  Opera,
} from 'mdi-material-ui';
import * as PropTypes from 'prop-types';
import React from 'react';
import { muiTheme } from '../theme';
import EnhancedDateFnsUtils from '../util/EnhancedGermanDateFnsUtils';

const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      'input:-webkit-autofill': {
        '&, &:hover, &:focus, &:active': {
          borderColor: 'inherit',
          caretColor: `${theme.palette.text.primary} !important`,
          transition: 'background-color 5000s ease-in-out 0s',
          WebkitBoxShadow: `0 0 0 9999px ${theme.palette.background.paper} inset !important`,
          WebkitTextFillColor: `${theme.palette.text.primary} !important`,
        },
      },
    },
  });

const GlobalStyles = withStyles(styles)(() => null);

interface Props {
  children: React.ReactNode;
}

const Application: React.FunctionComponent<Props> = ({ children }) => {
  const isInternetExplorer = checkIE(window.navigator.userAgent, {
    ie10: true,
    ie11: true,
    edge: false,
  }).isIE;

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <Dialog open={isInternetExplorer}>
        <DialogTitle>Internet Explorer wird nicht unterstützt</DialogTitle>
        <DialogContent>
          <Typography align={'center'} gutterBottom>
            Nutzen Sie einen der folgenden Browser
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TooltipIconButton
              color={'primary'}
              href={'https://www.google.com/intl/de_de/chrome/'}
              tooltip="Google Chrome"
            >
              <GoogleChrome />
            </TooltipIconButton>
            <TooltipIconButton
              color={'primary'}
              href={'https://www.mozilla.org/de/firefox/new/'}
              tooltip="Firefox"
            >
              <Firefox />
            </TooltipIconButton>
            <TooltipIconButton
              color={'primary'}
              href={'https://www.microsoft.com/de-de/edge'}
              tooltip="Microsoft Edge"
            >
              <MicrosoftEdge />
            </TooltipIconButton>
            <TooltipIconButton
              color={'primary'}
              href={'https://www.apple.com/de/safari/'}
              tooltip="Apple Safari"
            >
              <AppleSafari />
            </TooltipIconButton>
            <TooltipIconButton
              color={'primary'}
              href={'https://www.opera.com/de/download'}
              tooltip="Opera"
            >
              <Opera />
            </TooltipIconButton>
          </div>
        </DialogContent>
      </Dialog>
      <MuiPickersUtilsProvider utils={EnhancedDateFnsUtils} locale={deLocale}>
        <DialogProvider>
          <SnackbarProvider
            SnackbarProps={{ autoHideDuration: 4000 }}
            ButtonProps={{ color: 'inherit' }}
          >
            <CssBaseline />
            <GlobalStyles />
            {children}
          </SnackbarProvider>
        </DialogProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

Application.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Application;
