import React from 'react';
import {
  createStyles,
  Drawer,
  makeStyles,
  Theme,
  MenuItem,
  List,
} from '@material-ui/core';
import { Dashboard as DashboardIcon } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { OverflowMenu } from '@wa/werkstoff-core';
import AppBar from './AppBar';
import CollapsableMenuItem, {
  CollapsableMenuItemProps,
} from './CollapsableMenuItem';

export const drawerWidth = 240;

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      paddingTop: theme.spacing(7),
    },
    button: {
      color: 'inherit',
    },
  }),
);

interface Props {
  onLogout?: () => void;
}

function LinkMenuItem({
  path,
  ...other
}: CollapsableMenuItemProps & { path: string }) {
  const { push } = useHistory();
  const { pathname } = useLocation();
  return (
    <CollapsableMenuItem
      selected={pathname.startsWith(path)}
      onClick={() => {
        if (pathname !== path) {
          push(path);
        }
      }}
      {...other}
    />
  );
}

const MenuApp: React.FunctionComponent<Props> = ({ onLogout }) => {
  const classes = useStyles();

  return (
    <div>
      <AppBar
        title="Zenqnect Customer Portal"
        position="fixed"
        className={classes.appBar}
      >
        <OverflowMenu>
          <MenuItem onClick={onLogout}>Logout</MenuItem>
        </OverflowMenu>
      </AppBar>
      <Drawer
        variant="persistent"
        anchor="left"
        open
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
      >
        <List dense component="nav">
          <LinkMenuItem
            label="Connections"
            icon={<DashboardIcon />}
            path="/pipelines"
          />
        </List>
      </Drawer>
    </div>
  );
};

export default MenuApp;
