import React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  withStyles,
  Badge,
  createStyles,
  Theme,
  WithStyles,
  ListItemProps,
} from '@material-ui/core';
import ChevronUp from 'mdi-material-ui/ChevronUp';
import cx from 'clsx';

const styles = ({ palette }: Theme) =>
  createStyles({
    listItem: {
      height: 32,
      padding: '0 16px',
      '& h3': {
        fontWeight: 500,
      },
      '&$selectedItem': {
        backgroundColor:
          palette.type === 'light'
            ? palette.action.selected
            : 'rgba(255, 255, 255, 0.1)',

        '& $itemTextPrimary': {
          color:
            palette.type === 'light'
              ? palette.primary.main
              : palette.primary.light,
        },
        '& $itemIcon': {
          color:
            palette.type === 'light'
              ? palette.primary.main
              : palette.primary.light,
        },
      },
    },
    collapsed: {
      width: 32,
      padding: 4,
      '& $itemIcon': {
        marginRight: 0,
      },
    },
    selectedItem: {},
    itemText: {
      padding: 0,
    },
    itemTextPrimary: {
      lineHeight: 1,
      fontSize: '14px',
      fontWeight: 500,
      color: palette.action.active,
      padding: '6px 0',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    itemIcon: {
      width: 24,
      minWidth: 24,
      height: 24,
      padding: 2,
      marginRight: 14,
      '& > svg': {
        fontSize: '20px',
      },
    },
    itemIconEnd: {
      flexDirection: 'row-reverse',
      '& $itemIcon': {
        marginRight: 0,
        marginLeft: 14,
      },
    },
    toggleable: {
      '&:hover $toggleIcon, &:focus $toggleIcon': {
        opacity: 1,
      },
    },
    toggleIcon: {
      opacity: 0,
      transition: 'opacity .3s ease, transform 0.3s ease',
      marginRight: 0,
      marginLeft: 14,
    },
    tinyBadge: {
      margin: '2px 2px 0 0',
    },
  });

export type CollapsableMenuItemProps = Omit<
  ListItemProps<'div'>,
  'classes' | 'button' | 'onClick'
> & {
  badge?: React.ReactNode;
  collapsed?: boolean;
  children?: React.ReactNode;
  icon: React.ReactNode;
  iconPosition?: 'start' | 'end';
  label: string;
  open?: boolean;
  selected?: boolean;
  onToggle?: (open: boolean) => void;
  onClick?: () => void;
};

function CollapsableMenuItem({
  badge,
  classes,
  children,
  collapsed,
  icon,
  iconPosition = 'start',
  label,
  open,
  onToggle,
  selected,
  ...other
}: CollapsableMenuItemProps & WithStyles<typeof styles>) {
  if (children) {
    return (
      <React.Fragment>
        <Tooltip
          title={label}
          disableHoverListener={!collapsed}
          placement="right"
        >
          <ListItem
            button
            className={cx(classes.listItem, classes.toggleable, {
              [classes.collapsed]: collapsed,
            })}
            onClick={() => onToggle && onToggle(!open)}
            {...other}
          >
            {collapsed && (
              <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
            )}
            {!collapsed && (
              <ListItemText
                classes={{
                  root: classes.itemText,
                  primary: classes.itemTextPrimary,
                }}
                secondary={label}
              />
            )}
            {!collapsed && (
              <ListItemIcon
                className={classes.toggleIcon}
                style={{ transform: open ? 'rotate(0deg)' : 'rotate(180deg)' }}
              >
                <ChevronUp />
              </ListItemIcon>
            )}
          </ListItem>
        </Tooltip>
        {open && children}
      </React.Fragment>
    );
  }

  return (
    <Tooltip
      title={label}
      disableHoverListener={!collapsed}
      disableFocusListener={!collapsed}
      placement="right"
    >
      <ListItem
        button
        className={cx(classes.listItem, {
          [classes.selectedItem]: !!selected,
          [classes.collapsed]: collapsed,
          [classes.itemIconEnd]: iconPosition === 'end',
        })}
        {...other}
      >
        {badge && collapsed ? (
          <Badge
            color="secondary"
            variant="dot"
            classes={{ badge: classes.tinyBadge }}
          >
            <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
          </Badge>
        ) : (
          <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
        )}
        {!collapsed && (
          <ListItemText
            classes={{
              root: classes.itemText,
              primary: classes.itemTextPrimary,
            }}
            primary={label}
          />
        )}
        {badge && !collapsed && (
          <Badge badgeContent={badge} color="secondary">
            <span />
          </Badge>
        )}
      </ListItem>
    </Tooltip>
  );
}

export default withStyles(styles)(CollapsableMenuItem);
