import { red } from '@material-ui/core/colors';
import { AlertOutline } from 'mdi-material-ui';
import React from 'react';

// since the ErrorBoundary is used at the root layer, we can't use withStyles
const styles = {
  root: {
    maxWidth: 700,
    margin: '0 auto',
    paddingTop: 48,
    fontFamily: 'Roboto, sans-serif',
  },
  icon: {
    width: 100,
    height: 100,
    float: 'left',
    marginRight: 24,
  },
  title: {
    fontSize: '32px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    color: red[500],
    margin: 0,
  },
  text: {
    clear: 'both',
  },
  sendErrorReport: {
    background: red[500],
    color: '#fff',
    float: 'right',
    marginBottom: 24,
  },
  stacktrace: {
    overflowX: 'scroll',
    clear: 'both',
  },
} as const;

interface Props {
  children?: JSX.Element;
}

interface State {
  hasError: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  eventId?: string;
}

export default class ErrorBoundary extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null, eventId: undefined };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  static getDerivedStateFromError = (error: any) => ({ hasError: true, error });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    if (this.state.hasError) {
      return (
        <div style={styles.root}>
          <h1 style={styles.title}>
            <AlertOutline style={styles.icon} />
            Entschuldigung, da lief etwas falsch…
          </h1>
          <p style={styles.text}>
            Zenqnect Kundeninterface hat bei der zuletzt ausgeführten Aktion
            einen Fehler verursacht. Bitte versuchen Sie zunächst, die aktuelle
            Seite erneut zu laden.
            <br />
            Falls das Problem weiterhin besteht, wenden Sie sich bitte mit den
            untenstehenden Informationen an den Support.
          </p>
          <pre style={styles.stacktrace}>{this.state.error?.stack}</pre>
        </div>
      );
    }
    return this.props.children;
  }
}
