export interface RuntimeConfig {
  serverUrl: string;
}

const productionConfig: RuntimeConfig = {
  serverUrl: 'https://api.zenqnect.wertarbyte.app',
};

const preprodConfig: RuntimeConfig = {
  serverUrl: 'https://api.preprod.zenqnect.cloud',
};

const runtimeConfig: RuntimeConfig =
  process.env.NODE_ENV === 'production'
    ? window.location.hostname.includes('.preprod.')
      ? preprodConfig
      : productionConfig
    : productionConfig;

export default runtimeConfig;
