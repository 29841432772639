import { ApolloProvider } from '@apollo/client';
import {
  createStyles,
  LinearProgress,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, { Suspense, lazy, useCallback } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

import apolloClient from './util/apolloClient';
import Application from './components/Application';
import AppMenu from './components/AppMenu';

import './App.css';
import { logout } from './modules/login/LoginAPI';
const LoginRoutes = lazy(() => import('./modules/login/routes'));
const PipelineRoutes = lazy(() => import('./modules/pipelines/routes'));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(8, 2, 2),
    },
  }),
);

const App: React.FunctionComponent = () => {
  const classes = useStyles();

  const history = useHistory();

  const handleLogout = useCallback(() => {
    logout(apolloClient);
    history.push('/');
  }, [history]);

  return (
    <ApolloProvider client={apolloClient}>
      <Application>
        <Switch>
          <Suspense fallback={<LinearProgress />}>
            <LoginRoutes />
            <Route
              path="/pipelines"
              render={() => (
                <div className={classes.root}>
                  <AppMenu onLogout={handleLogout} />
                  <div className={classes.content}>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/pipelines" />}
                    />
                    <PipelineRoutes />
                  </div>
                </div>
              )}
            />
          </Suspense>
        </Switch>
      </Application>
    </ApolloProvider>
  );
};

export default App;
